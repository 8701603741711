/** @jsx jsx */
import React, { useState, useMemo } from "react";
import { graphql } from "gatsby";
import { jsx, Container, Box } from 'theme-ui';
import Layout from '../components/Layout';
import CardWrapper from "../components/shared/CardWrapper";
import Pagination from "../components/shared/pagination/Pagination";
import HeroSection from "../components/sections/heroSection";
import SEO from "../components/seo";
import SearchComponent from "../components/shared/SearchComponent";

export const pageQuery = graphql`
  query authorIndex {
    allContentfulPage(filter: {slug: {eq: "authors-list"}}) {
      nodes {
        id
        seoMetadata {
          description
          keywords
          ogImage {
            gatsbyImageData
            title
          }
          title
        }
        sections {
          ... on ContentfulHeroSection {
            ...ContentfulHeroSectionFragment
          }
        }
        bannerData {
          bannerImage {
            gatsbyImageData(width: 30)
          }
          bannerSlug
          bannerText {
            raw
          }
          label
          link
        }
      }
    }
    allContentfulAuthor(sort: {order: ASC, fields: name}) {
      edges {
        node {
          id
          slug
          internalName
          name
          description {
            raw
          }
        }
      }
      totalCount
    }
    file(relativePath: {eq: "background-pattern.png"}) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 2000,
        )
      }
    }
  }
`;

const AuthorIndex = ({ data }) => {
  const { sections, seoMetadata, bannerData} = data.allContentfulPage.nodes && data.allContentfulPage.nodes.length > 0 ? data.allContentfulPage.nodes[0] : [];
  const allAuthors = [...data.allContentfulAuthor.edges];

  const pageNumberLimit = 9;
  const paginationLimit = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onPrevClick = () => {
    if ((currentPage - 1) <= minPageLimit) {
      setMaxPageLimit(maxPageLimit - (paginationLimit - 1));
      setMinPageLimit(minPageLimit - (paginationLimit - 1));
    }

    setCurrentPage(prev => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + (paginationLimit - 1));
      setMinPageLimit(minPageLimit + (paginationLimit - 1));
    }

    setCurrentPage(prev => prev + 1);
  };

  const paginatedPosts = useMemo(() => {
    const authors = allAuthors.slice(((currentPage - 1) * pageNumberLimit), ((currentPage - 1) * pageNumberLimit) + pageNumberLimit);
    return authors;
  }, [allAuthors, currentPage, pageNumberLimit]);


  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    totalPages: Math.ceil((data.allContentfulAuthor.totalCount) / pageNumberLimit),
  };
  return (
    <Layout bannerData={bannerData}>
      {seoMetadata && (
        <SEO
          title={seoMetadata.title}
          description={seoMetadata.description}
        />
      )}

      <Box sx={{ position: 'relative', pt: [4], backgroundColor: "#F4F4F7", pb: [4] }}>
        <Container>
          <Box sx={{
            display: 'flex',
            rowGap: "30px",
            listStyle: 'none',
            flexWrap: 'wrap',
            justifyContent: ['center', null, 'space-between'],
            alignItems: 'center',
            padding: 0,
            my: [2, 4],
            '&::after': {
              content: '""',
              flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%']
            }
          }}>
            {paginatedPosts.map(({ node: post }) => (
              <Box
                key={post.id}
                sx={{
                  flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%'],
                  display: 'flex',
                  margin: '6px 0',
                }}
              >
                <CardWrapper
                  item={{
                    heading: post?.frontmatter?.name || post?.name,
                    linkUrl: `/authors/${post.slug}`,
                    cardHeight: '520px',
                  }}
                />

              </Box>
            ))}
          </Box>
          <Pagination
            {...paginationAttributes}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            onPageChange={onPageChange} />
        </Container>
        <SearchComponent dataFor="blog"/>
      </Box>
      {sections && sections.length > 0  && <HeroSection sectionContent={sections[0]} />}
    </Layout>
  );
};

export default AuthorIndex;
